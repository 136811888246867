import { useExperienceControls } from "@/app/newLayoutComponents/Canvas/useExperienceControls";
import { useControls, folder } from "leva";

export const useDevelopmentControls = () => {
  const {
    avatarPosition,
    keyLightIntensity,
    keyLightPosition,
    keyLightAngle,
    keyLightPenumbra,
    keyLightRotation,
    fillLightIntensity,
    fillLightPosition,
    fillLightAngle,
    fillLightPenumbra,
    fillLightRotation,
    backLightIntensity,
    backLightPosition,
    backLightAngle,
    backLightRotation,
    backLightPenumbra,
  } = useExperienceControls();

  const [values] = useControls(() => ({
    "Avatar Position": folder({
      avatarPosition: {
        value: avatarPosition,
        label: "Position",
      },
    }),
    "Key Light": folder({
      keyLightPosition: {
        value: keyLightPosition,
        label: "Position",
      },
      keyLightRotation: { value: keyLightRotation, label: "Rotation" },
      keyLightIntensity: {
        value: keyLightIntensity,
        label: "Intensity",
      },
      keyLightAngle: {
        value: keyLightAngle,
        label: "Angle",
      },
      keyLightPenumbra: {
        value: keyLightPenumbra,
        label: "Penumbra",
      },
    }),
    "Fill Light": folder({
      fillLightPosition: {
        value: fillLightPosition,
        label: "Position",
      },
      fillLightRotation: { value: fillLightRotation, label: "Rotation" },
      fillLightIntensity: {
        value: fillLightIntensity,
        label: "Intensity",
      },
      fillLightAngle: {
        value: fillLightAngle,
        label: "Angle",
      },
      fillLightPenumbra: {
        value: fillLightPenumbra,
        label: "Penumbra",
      },
    }),
    "Back Light": folder({
      backLightPosition: {
        value: backLightPosition,
        label: "Position",
      },
      backLightRotation: { value: backLightRotation, label: "Rotation" },
      backLightIntensity: {
        value: backLightIntensity,
        label: "Intensity",
      },
      backLightAngle: {
        value: backLightAngle,
        label: "Angle",
      },
      backLightPenumbra: {
        value: backLightPenumbra,
        label: "Penumbra",
      },
    }),
  }));

  return {
    avatarPosition: values.avatarPosition,
    fillLightPosition: values.fillLightPosition,
    fillLightIntensity: values.fillLightIntensity,
    fillLightAngle: values.fillLightAngle,
    fillLightPenumbra: values.fillLightPenumbra,
    fillLightRotation: values.fillLightRotation,
    backLightPosition: values.backLightPosition,
    backLightIntensity: values.backLightIntensity,
    backLightAngle: values.backLightAngle,
    backLightPenumbra: values.backLightPenumbra,
    backLightRotation: values.backLightRotation,
    keyLightIntensity: values.keyLightIntensity,
  };
};
